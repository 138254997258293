* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;

}

/* VARIABLES */
:root {
    --small-screen-res: 320px;
    --medium-screen-res: 768px;
    --large-screen-res: 1200px;
    --primary-color: rgba(11, 98, 164, 1);
    --secondary-color: #35a2f5;
    --danger-color: #dc3545;
    --success-color: #3da188;
}

body {
    font-family: 'Roboto', sans-serif;
}

header {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)), url("/assets/images/brand/hero2000.webp") center center/cover;
    min-height: 100vh;
}

@media only screen and (max-width: 1600px) {
    header {
        background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)), url("/assets/images/brand/hero1500.webp") center center/cover;
    }
}

@media only screen and (max-width: 1024px) {
    header {
        background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)), url("/assets/images/brand/hero1024.webp") center center/cover;
    }
}

@media only screen and (max-width: 1023px) {
    header {
        background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)), url("/assets/images/brand/hero500.webp") center center/cover;
    }
}

.bg-primary {
    background-color: var(--primary-color);
}

a {
    cursor: pointer;
}

.text-white {
    color: white;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-1 {
    margin-top: 1em !important;
}

.mt-2 {
    margin-top: 2em !important;
}

.mt-3 {
    margin-top: 3em !important;
}

.mt-4 {
    margin-top: 4em !important;
}

.mt-5 {
    margin-top: 5em !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 1em !important;
}

.mb-2 {
    margin-bottom: 2em !important;
}

.mb-3 {
    margin-bottom: 3em !important;
}

.mb-4 {
    margin-bottom: 4em;
}

.mb-5 {
    margin-bottom: 5em !important;
}

.ml-1 {
    margin-left: 1em;
    ;
}

.ml-2 {
    margin-left: 2em;
    ;
}

.ml-3 {
    margin-left: 3em;
    ;
}

.ml-4 {
    margin-left: 4em;
    ;
}

.ml-5 {
    margin-left: 5em;
    ;
}

.mr-1 {
    margin-right: 1em;
    ;
}

.mr-2 {
    margin-right: 2em;
    ;
}

.mr-3 {
    margin-right: 3em;
    ;
}

.mr-4 {
    margin-right: 4em;
    ;
}

.mr-5 {
    margin-right: 5em;
    ;
}

.pt-1 {
    padding-top: 1em !important;
}

.pt-2 {
    padding-top: 2em !important;
}

.pt-3 {
    padding-top: 3em !important;
}

.pt-4 {
    padding-top: 4em !important;
}

.pt-5 {
    padding-top: 5em !important;
}

.pb-1 {
    padding-bottom: 1em !important;
}

.pb-2 {
    padding-bottom: 2em !important;
}

.pb-3 {
    padding-bottom: 3em !important;
}

.pb-4 {
    padding-bottom: 4em !important;
}

.pb-5 {
    padding-bottom: 5em !important;
}

.underline-dotted {
    border-bottom: 2px dotted;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    line-height: 1.2;
    font-weight: 700;
}

.position-relative {
    position: relative;
}

.m-auto {
    margin: auto;
}

.d-none {
    display: none !important;
}

.d-block {
    display: block !important;
}

/* img .logo {
    max-width: 50%;
    max-height: 50%;
} */

@media only screen and (min-width: 320px) {
    .d-xs-block {
        display: block !important;
    }

    .d-xs-none {
        display: none !important;
    }
}

@media only screen and (min-width: 768px) {
    .d-md-block {
        display: block !important;
    }

    .d-md-none {
        display: none !important;
    }

    .mt-md-2 {
        margin-top: 2em !important;
    }
}

@media only screen and (min-width: 1024px) {
    .d-lg-block {
        display: block !important;
    }

    .d-lg-none {
        display: none !important;
    }


}


.d-flex {
    display: flex !important;
}

.d-inline {
    display: inline !important;
}

/* HERO */

.hero {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    position: relative;

}


.hero>.wrapper>.title {
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 2rem;
}

.hero>.wrapper>.subtitle {
    margin-top: 0;
    font-size: 2rem;
    font-family: 'Poppins', sans-serif;
}

.hero>.wrapper>.information {
    margin-top: 3rem;
    font-size: 1.5rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 800;
}


.hero>.wrapper>.title>h1>.highlighted {
    /* color: #FF6700; */
    color: var(--secondary-color);
}


@media only screen and (min-width: 768px) and (max-width: 850px) {

    .hero>.wrapper>.subtitle {
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 750px),
screen and (max-height: 500px) {
    .hero>.wrapper>.title {
        font-size: 1rem;

    }

    .hero>.wrapper>.information {

        font-size: 1rem;

    }

    .hero>.wrapper>.subtitle {
        font-size: 0.8rem;
    }

    .hero>.wrapper>.information {

        font-size: 0.9rem;

    }
}

@media only screen and (max-width: 350px) {
    .hero>.wrapper>.subtitle {
        font-size: 0.7rem;
    }

    .hero>.wrapper>.information {

        font-size: 0.8rem;

    }
}




/* COUNTER */

.hero>.counter-box {
    height: 15vh;
    background-color: rgba(11, 98, 164, 0.8);
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

}

.hero>.counter-box>.counter-item {

    border-right: #cfcdcd solid 1px;
    color: white;
    text-align: center;
    text-align: center;
    width: 25%;
    margin: 0;

}

.hero>.counter-box>.counter-item>.counter-item-title {
    font-size: 3.5rem;
    font-weight: 800;
}

.hero>.counter-box>.counter-item>.counter-item-subtitle {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 600;
}

.hero>.counter-box>.counter-item:last-child {
    border-right: 0;
}

@media only screen and (max-width: 500px),
screen and (max-height:767px) {

    .hero>.counter-box {
        height: 20vh;

    }

    .hero>.counter-box>.counter-item>.counter-item-title {
        font-size: 2rem;
        font-weight: 600;
    }

    .hero>.counter-box>.counter-item>.counter-item-subtitle {
        font-size: 0.8rem;
        font-weight: 400;
    }

}

/* NAVBAR */

.navbar-wrapper {
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    margin-right: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
    width: 100%;

}

.navbar-wrapper>.logo {
    position: relative;
    margin-left: 80px;
}

.navbar-wrapper>.menu.desktop {
    list-style: none;
    display: none;
}


.navbar-wrapper>.menu.desktop>li {
    display: inline-block;
    margin-right: 50px;
    text-transform: uppercase;

}

.navbar-wrapper>.menu.desktop>li:last-child {
    margin-right: 0;

}

.navbar-wrapper .menu a {
    color: white;
    text-decoration: none;
}

.navbar-wrapper .menu a:hover {
    text-decoration: underline;
}

/* HAMBUERGUER MENU */

.menu-mobile-icon-wrapper {
    justify-self: center;
    align-self: center;
    height: 100%;
}

.menu-mobile-icon {
    height: 32px;
    width: 43px;
    margin-right: 80px;
    position: relative;
    top: 0;
    right: 3%;
    display: inline-block;
    vertical-align: middle;
    z-index: 20;

}

.menu-mobile-icon span {
    display: block;
    background: white;
    width: 100%;
    height: 2px;
    margin-top: -2px;
    position: absolute;
    left: 0;
    top: 50%;
}


.menu-mobile-icon:before,
.menu-mobile-icon:after {
    content: "";
    display: block;
    background: white;
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    transform-origin: center center;
    transform: rotate(0deg);
    transition: all 0.3s ease;
}

.menu-mobile-icon:before {
    top: 2px;
    margin-top: -2px;
}

.menu-mobile-icon:after {
    bottom: 2px;
    margin-bottom: -2px;
}

.menu-shown .menu-mobile-icon span {
    background: transparent;
}

.menu-shown .menu-mobile-icon:before {
    top: 50%;
    transform: rotate(45deg);
}

.menu-shown .menu-mobile-icon:after {
    bottom: 50%;
    transform: rotate(-45deg);
}

.menu-shown .menu.mobile {
    transform: translateX(0);
}

.menu.mobile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    text-align: center;
    padding-top: 112px;
    background: rgba(11, 98, 164, 1);
    z-index: 10;
    transition: all .4s ease-in-out;
    transform: translateX(-100%);
}

.z-50 {
    z-index: 50
}

.mobile .menu-item {
    display: block;
    line-height: 2;
    padding: 25px 0;
}


@media only screen and (min-width: 1400px) {
    .menu-mobile-icon {
        display: none;
    }

    .navbar-wrapper>.menu.mobile {
        display: none;
    }

    .navbar-wrapper>.menu.desktop {
        display: block;
    }

    .navbar-wrapper {
        display: flex;
        flex-flow: row wrap;
    }

}

@media only screen and (max-width: 767px),
(max-width: 767px) and (min-height:500px) {
    .menu-mobile-icon {
        height: 25px;
        width: 35px;
        top: 20px;
        position: absolute;
        margin: 0;
    }

    .navbar-wrapper>.logo {
        width: 100%;
        margin-left: 0;
    }

    .navbar-wrapper {
        position: absolute;
        text-align: center;
        padding: 35px 0;
    }

    .navbar-wrapper>.logo {
        position: absolute;
    }

}

/* SECTION */

.section {
    margin-top: 10rem;
    text-align: justify;
    line-height: 1.9;
    font-weight: 400;
    /* color: #6a6b7c; */
    font-family: 'Poppins', sans-serif;

}

.section-title {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 100px;
}


@media only screen and (max-width: 320px) {
    .section-title {
        font-size: 1.5rem
    }
}

/* .bottom-line {
    width: 15rem;
    border: var(--secondary-color) 1px solid;
    margin-top: 0;
    margin-bottom: 30px;
} */


/* MAIN */



.container {
    /* height: 100vh; */
    max-width: 1200px;
    margin: auto;
    padding: 30px 30px;
}

.container-fluid {
    width: 100%;
}

.event-box {
    border: #cec9c9 1px thin;
    box-shadow: 0px 10px 80px 0px rgba(219, 219, 219, 0.91);
    background: white;
}

.event-box-body {
    padding: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    text-align: justify;
    font-size: 1.09rem;
    line-height: 1.9;

}

.event-box-title {
    padding: 30px 30px 0 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
    text-align: justify;
    color: var(--primary-color);
    text-transform: uppercase;

}

.event-box-subtitle {
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;

}

.event-box-subtitle>div {
    width: 50%;
    text-align: center;
}

.event-box-subtitle>div:first-child {
    border-right: 1px solid #b8b2b2;
}


.event-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
}

@media only screen and (max-width: 850px) {
    .event-grid {
        grid-template-columns: 1fr;
        row-gap: 20px;
    }
}

/* ABOUT */

.about-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 80px;
    position: relative;
}

.about-title {
    margin-bottom: 1.5rem;
    font-weight: 800;
    font-family: 'Roboto', sans-serif;
}

.about-body {
    text-align: justify;
    line-height: 1.9;
    margin-top: 3rem;
    font-weight: 400;
    /* color: #6a6b7c; */
    font-family: 'Poppins', sans-serif;

}

.about-image {
    border-radius: 10px;
    box-shadow: black 0 12px 12px 0;
    position: relative;
    overflow: hidden;

    height: 100%;
    /* height: 500px; */
}

.about-image>img {
    height: 100%;
}



/* .about-image.image-1 {
    background: url("../images/brand/fabian500x500.jpg") center center/cover;
}

.about-image.image-2 {
    background: url("../images/brand/tour-1-500x500.jpg") center center/cover;
} */

@media only screen and (max-width: 1024px) {

    .about-wrapper {
        grid-template-columns: 1fr;
    }

    .about-image {
        justify-self: center;
    }
}

/* TABS */

.tabs {
    width: 100%;

    background-color: var(--primary-color);
    font-size: 0;
    /* Removes spacing between buttons*/
    margin: 0;
}

.tab-link {
    padding: 15px;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    margin: 0;
    display: inline-block;
    transition: 0.3s;
    outline: none;
}

.tab-link:hover,
.tab-link.show {
    background-color: #35a2f5;
}

.tab-content {
    border: 1px solid #cfcdcd;
    margin: 0;
    padding: 20px;
    width: 100%;
    display: none;
    overflow-x: hidden;
    background-color: white;
    animation: fade 1s;
}

.tab-content.show {
    display: block;
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* EVENT */

.event-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding: 10px 30px;
    column-gap: 15px;
}

.event-wrapper .event-image {
    justify-self: center;
}

.event-wrapper .event-description {
    line-height: 1.9;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}


.event-wrapper .event-description .panelist-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 1rem;
    align-items: center;
}

.event-wrapper .event-description .panelist {
    display: flex;
    justify-content: start;
    align-items: center;
}

.event-wrapper .event-description .panelist>div {
    display: inline-block;
}

.event-wrapper .event-description .panelist .image {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: inline-block;
    overflow: hidden;
    margin-right: 10px;

}

.event-wrapper .event-description .panelist .image>img {
    height: 100%;
    width: auto;

}

.event-wrapper .event-description .event-date .event-icon {

    font-size: 1.5rem;
}

.event-wrapper .event-description .event-date span {
    margin-right: 10px;
    font-size: 3rem;
    color: var(--primary-color);

}

@media only screen and (max-width: 768px) {
    .event-wrapper {
        grid-template-columns: 1fr;
    }

    .event-wrapper .event-description .panelist-wrapper {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media only screen and (max-width: 500px) {
    .event-wrapper .event-description .event-date {
        text-align: center;
    }

    .event-wrapper .event-description .event-date span {
        display: block;
    }

    .event-wrapper .event-description .panelist {
        justify-content: center;
        flex-flow: column wrap;
        align-items: center;
    }
}

@media only screen and (max-width: 320px) {
    .event-wrapper .event-description .panelist-wrapper .panelist {
        display: flex;
        justify-items: center;
        align-items: center;
        flex-flow: column wrap;
        text-align: center;
    }
}

/* PANELISTS */

.panelist {
    position: relative;
    line-height: normal;
}

#panelists .panelist-list {
    list-style: none;
}

#panelists .panelist-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

#panelists .panelist-list>li {
    margin-bottom: 30px;
}

#panelists .panelist-list>li>div {
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

#panelists .panelist-list>li>div>img {
    height: 100%;
}

#panelists .panelist-list li .overlay {
    position: absolute;
    width: 100%;
    height: 15%;
    transition: 0.3s ease;
    background-color: var(--primary-color);
    bottom: 50px;
    left: 0;
    right: 0;
    bottom: 0;
}

#panelists .panelist-list li .overlay .overlay-text {
    color: transparent;
    font-size: 1.1rem;
    width: 100%;
    position: absolute;
    height: 100%;
    padding: 30px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    transition: 0.1s;

}

#panelists .panelist-list li .overlay .overlay-text .social-icons {
    list-style: none;
    justify-content: center;


}

#panelists .panelist-list li .overlay .overlay-text .social-icons li {
    display: inline-block;
    font-size: 1.7rem;
    margin-right: 30px;

}

#panelists .panelist-list li .overlay .overlay-text .social-icons li a {
    color: white;

}

#panelists .panelist-list li .overlay .overlay-text .social-icons li:last-child {
    margin-right: 0;

}

#panelists .panelist-list li .overlay .overlay-text .social-icons li a:hover {
    color: var(--secondary-color)
}

#panelists .panelist-list li .overlay .badge-overlay {
    color: white;
    padding: 10px;
    font-size: 1.1rem;
    height: 100%;
    width: 100%;
}

/* #panelists .panelist-list li .overlay .overlay-text {
    color: transparent;
    transition: 0.5s;

} */
/* #panelists .panelist-list li .badge {
    background-color: var(--primary-color);
    height: 20%;
    bottom: 0;
    position: absolute;
    width: 100%;
    color: white;

} */



/* #panelists .panelist-list li .overlay:hover .badge {
    background-color: var(--primary-color);
} */



@media only screen and (min-width: 768px) {
    #panelists .panelist-list li:hover .overlay {
        height: 100%;
    }

    #panelists .panelist-list li:hover .overlay .overlay-text {
        color: white;
        transition: 0.3s;
        transition-delay: 0.2s;
    }

    #panelists .panelist-list li:hover .overlay .badge-overlay {
        color: transparent;
        display: none;
        /* transition: 0.3s;
        transition-delay: 0.2s; */
    }
}

@media only screen and (max-width: 1024px) {
    #panelists .panelist-list {
        justify-content: space-around;
    }
}

@media only screen and (max-width: 768px) {

    #panelists .panelist-list li .overlay {
        height: 20%;
    }

    #panelists .panelist-list li .overlay .badge-overlay {
        font-size: 0.7rem;
    }

    #panelists ul {
        flex-flow: column wrap;
        align-items: center;
    }


    #panelists ul>li {
        margin-bottom: 10px
    }

    #panelists ul>li:last-child {
        margin-bottom: 0;
    }

    /* #panelists .panelist-list>li>div {

        width: 250px;

    }

    #panelists .panelist-list li .overlay {
        width: 250px;
    } */
}

/* FOOTER */
footer {
    height: 20vh;
    width: 100%;
    background-color: var(--primary-color);
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    color: white;
    align-items: center;
    overflow: hidden;
    text-align: center;
}

footer a {
    color: white;
    text-decoration: none;
}

footer a:hover {
    text-decoration: underline;
}

footer .contact {
    list-style: none;
}

footer .contact li {
    display: inline;
    margin-right: 1rem;
}

footer .contact li:not(:last-child):after {
    content: " | ";
    margin-left: 1rem;
}

footer div:first-child {
    font-size: 1.3rem;
    margin-bottom: 1rem;
}

footer div:nth-child(2) {
    margin-bottom: 1rem;
}

@media only screen and (max-width: 500px) {

    footer {
        height: 30vh;
    }

    footer div:first-child {
        font-size: 1rem;
    }

    footer .contact li {
        display: block;
        margin-right: 0;
    }

    footer .contact li:not(:last-child):after {
        content: "";
        margin-left: 0;
    }


}


/* FORM */
#enroll-form {

    margin-bottom: 50px;
    width: 100%;
    border: 1px solid var(--primary-color);
    padding: 30px;
    background-color: var(--primary-color);
    border-radius: 15px;
    display: flex;
    justify-content: center;
}


#enroll-form .form-container {

    position: relative;
    max-width: 600px;
    background-color: white;
    height: 100%;
    margin: auto;
    padding: 30px;
    border-radius: 15px;
    overflow: visible;
}

#enroll-form .form-container .loading {

    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    padding: 1rem;
    opacity: 0.8;
    left: 0;
    top: 0;
    border-radius: 15px;
    z-index: 1000;
    animation: fade-loader 0.5s;
}

#enroll-form .form-container .loading .text {

    position: absolute;
    color: white;
    top: 40%;
    text-align: center;
    font-size: 1.5rem;
}

@keyframes fade-loader {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.8;
    }
}


.form-group {
    margin: 10px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}


input.form-control,
select.form-control {
    width: 70%;
    height: 46px;
    border-radius: 5px;
    border: 1px solid #c3c1c1;
    font-size: 0.9rem;
    padding: 10px;

}

input.form-control.error,
select.form-control.error {
    border: 1px solid var(--danger-color);
}

label.error {
    font-size: 0.8rem;
    text-align: right;
    color: var(--danger-color);
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    display: block;
}

div>.form-group-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 10px;
    font-weight: 600;
}

.btn {
    padding: 10px;
    border-radius: 5px;
    display: block;
    cursor: pointer;
    font-size: 0.8rem;

}

.btn.btn-success {

    background-color: var(--success-color);
    border: 1px var(--success-color) solid;
    color: white;
}

.btn.btn-danger {

    background-color: var(--danger-color);
    border: 1px var(--danger-color) solid;
    color: white;
}

.btn.btn-danger:hover {

    background-color: #c52a3a;
}

.btn.btn-success:hover {
    background-color: #2e8576;
    /* 
      Hover background color for button is #37a08e
    */
}

@media only screen and (max-width: 500px) {
    .form-group {
        flex-flow: column wrap;
        justify-content: center;
        align-content: center;
    }

    div>.form-group-title {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
    }

    input.form-control,
    select.form-control {
        width: 100%;
    }
}


/* MESSAGES */

.alert {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    animation: fade 1s;

}

.alert.alert-danger {
    background-color: var(--danger-color);
    color: white;
}

.alert.alert-success {
    background-color: var(--success-color);
    color: white;
}

.success-enrollment {
    color: white;
    padding: 0px 30px;
}

.success-enrollment .text {
    font-size: 1.1rem;
    margin-top: 1rem;
    text-align: justify;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;

}

.success-enrollment .title {

    font-weight: 600;
    font-size: 2rem;
    text-align: center;
}

/* ACCORDION */

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
    background-color: var(--primary-color);
    color: white;
    border: none;
    /* border-right: none;
    border-bottom: rgb(194, 188, 188) solid 1px; */
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    font-size: 1rem;
    outline: none;
    transition: 0.4s;
}

.accordion-wrapper {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}

.accordion:after {
    content: '\0276F';
    /* Unicode character for "plus" sign (+) */
    font-size: 13px;
    color: white;
    float: right;
    margin-left: 5px;
}

.active:after {
    transform: rotate(90deg);

}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active,
.accordion:hover {
    background-color: var(--secondary-color);
}

/* Style the accordion panel. Note: hidden by default */
.accordion+.panel {
    font-family: "Poppins", sans-serif;
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease-out;
    border-right: #eee 1px solid;
    border-left: #eee 1px solid;

}

.accordion+.panel:last-child {
    border-bottom: #eee 1px solid;
}

/* TOOLTIP */

.tooltip {
    position: relative;
    cursor: pointer;
}

.tooltip .tooltip-text {
    position: absolute;
    display: none;
    background-color: white;
    border: 1px solid #eee;
    /*min-width: 300px;*/
    padding: 20px 30px;
    bottom: 30px;
    font-size: 0.9rem;
}

.tooltip:hover .tooltip-text {
    display: block;
}

@media only screen and (max-width: 500px) {

    .tooltip .tooltip-text {
        position: absolute;
        width: 100%;
        min-width: 0;

    }

}